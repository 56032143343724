.adv_cal_cap {
  #adv_cal_cap_container {
    background-position: top;
    background-size: 100% 100%;
    .adv-cal-cap-wrapper {
      margin-top: 40px;
      margin-bottom: 40px;
      .adv-cal-cap-title {
        @include breakpoint($large-only) {
          font-size: 75px;
        }
      }
      .cards {
        .card {
          @include breakpoint($small-down) {
            -webkit-flex: 1 40%;
            flex: 1 40%;
          }
          &.expired {
            background-size: cover;
          }
          &.active {
            border-style: solid;
          }
        }
      }
    }
    .adv-cal-cap-popup {
      .popup-content-wrapper {
        .popup-close {
          top: 18px;
          right: 22px;
          background: none;
          @include breakpoint($small-down) {
            top: 15px;
          }
          &:before,
          &:after {
            @include breakpoint($small-down) {
              height: 25px;
            }
            height: 40px;
            background: $color-black;
          }
        }
        .offer-details {
          &__panel {
            width: 100%;
            height: auto;
            .messages {
              position: relative;
            }
          }
          &__img {
            display: none;
          }
          &__content {
            text-align: center;
            width: 100%;
            @include breakpoint($small-down) {
              height: 500px;
              overflow-y: scroll;
            }
            @include breakpoint($medium-up) {
              width: 93%;
              margin: 0 25px 20px;
              position: relative;
            }
            .offer-details__img {
              margin-top: 0;
              text-align: center;
              width: 99%;
              overflow: hidden;
              display: table;
              img {
                width: 100%;
              }
            }
          }
          &__controls {
            text-align: center;
            width: 100%;
            @include breakpoint($medium-up) {
              left: 53%;
              bottom: 0;
              position: absolute;
              width: auto;
            }
            .btn-wrapper {
              padding-bottom: 4%;
              @include breakpoint($medium-up) {
                padding-bottom: 20px;
              }
              a {
                height: 30px;
                line-height: 2;
                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
